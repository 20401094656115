/* eslint-disable @typescript-eslint/no-base-to-string,react/no-unescaped-entities */
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';
import { graphql, StaticQuery } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

interface PictureWidgetProps {
  src?: any;
  src2?: any;
}

function PictureWidget() {
  return (
    <StaticQuery
      query={graphql` query pictureQuery {
          src: file(relativePath: {eq: "img/desktop.jpeg"}) {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 800, layout: FIXED)
            }
          }
          src2: file(relativePath: {eq: "img/desktop2.jpeg"}) {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 800, layout: FIXED)
            }
          }
        }
      `}
      render={(data: PictureWidgetProps) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={getSrc(data.src2)} alt="Coding doggo" style={{ width: '100%' }} />
          <p style={{ textAlign: 'center' }}>Coding doggo</p>
          <img src={getSrc(data.src)} alt="Front view with everything on" style={{ width: '100%' }} />
          <p style={{ textAlign: 'center' }}>Front view with everything on</p>
        </div>
      )}
    />
  );
}

const containerStyle = css`
  .site-main {
    margin-right: 24px;
    margin-left: 24px;
  }
`

function DeskSetup() {
  return (
    <IndexLayout css={containerStyle}>
      <Helmet>
        <title>Desk Setup</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false}/>
            </div>
          </div>
        </header>
        <main id="site-main" className={`site-main ${SiteMain} ${outer}`}>
          <div css={inner}>
            <article className={`${PostFull} post page ${NoImage}`}>
              <PostFullHeader>
                <PostFullTitle>Desk Setup</PostFullTitle>
              </PostFullHeader>

              <PostFullContent className="post-full-content">
                <div className="text-container">
                  <p>
                    So, this is an overview of my current work-from-home setup which I use mostly for coding and
                    the occasional writing. Some of this stuff I really like, some of them just gets the job done,
                    but in general I am pretty happy with it.
                    <p/>
                    <p/>
                  </p>
                </div>
                <div className="post-content">
                  <h2>What it looks like</h2>
                  <PictureWidget/>
                  <h2>Furniture</h2>
                  <ul>
                    <li>Desk: <a href="https://www.ikea.com/de/de/p/idasen-schreibtisch-sitz-steh-braun-dunkelgrau-s39281004/">Ikea IDÄSEN</a></li>
                    <li>Chair: <a href="https://www.ikea.com/nl/en/p/styrspel-gaming-chair-dark-grey-grey-20522032/#content">Ikea STYRSPEL</a></li>
                  </ul>
                  <h2>Screens</h2>
                  <ul>
                    <li>Vertical Monitor: <a href="https://www.amazon.de/Dell-Monitor-Display-Reaktionszeit-General%C3%BCberholt/dp/B07QGQ2JZ6/ref=d_pd_day0_sccl_2_1/261-5705031-0530353">Dell P2417H</a></li>
                    <li>Horizontal Monitor: <a href="https://www.amazon.com/SAMSUNG-Adjustable-TUV-Certified-Intelligent-LS27A600UUNXGO/dp/B08YGQBB69">SAMSUNG S60UA</a></li>
                  </ul>
                  <h2>Devices</h2>
                  <ul>
                    <li>Android: <a href="https://www.samsung.com/us/smartphones/galaxy-s22/">Samsung S22</a></li>
                    <li>iOS: <a href="https://www.apple.com/uk/ipad-10.2/">Ipad 9</a></li>
                    <li>Linux/Windows: <a href="https://www.youtube.com/watch?v=c-yVoyXfmLw">Dell XPS 15 9560</a></li>
                    <li>Mac: <a href="https://www.apple.com/uk/macbook-pro-14-and-16/">16-inch Apple M1 Pro 32gb</a></li>
                  </ul>
                  <h2>Gadgets</h2>
                  <ul>
                    <li>Camera: <a href="https://www.amazon.de/Logitech-C922-kostenloser-3-monatiger-XSplit-Lizenz/dp/B01L6L52K4/">Logitech C922 Pro</a></li>
                    <li>Drawing Tablet: <a href="https://estore.wacom.com/en-DE/wacom-intuos-small-bluetooth-pistachio-ctl-4100wle-s.html">Wacom Intuos BT S</a></li>
                    <li>Headphone: <a href="https://www.sony.de/electronics/kopfband-kopfhoerer/wh-ch710n">Sony WHCH710N</a></li>
                    <li>Keyboard: <a href="https://www.keychron.com/pages/keychron-k8-wireless-mechanical-keyboard">Keychron K8</a></li>
                    <li>Key-light: <a href="https://www.amazon.de/gp/product/B095K3DG9Z/ref=ppx_yo_dt_b_asin_title_o06_s00?ie=UTF8&psc=1">ULANZI K13</a></li>
                    <li>Mic: <a href="https://www.amazon.de/gp/product/B00BJ17WKK/ref=ppx_yo_dt_b_asin_title_o05_s00?ie=UTF8&psc=1">V-MODA BoomPro</a></li>
                    <li>Mouse: <a href="https://www.amazon.de/-/en/Exclusive-Bluetooth-connection-Rechargeable-multi-device/dp/B0761YPDNM">Logi MX Anywhere 2s</a></li>
                  </ul>
                </div>
                <div className="text-container">
                  <p>
                    Now, if you care to read the details, here's some of the highlights of that setup.
                    <p/>
                    <p/>
                    <strong>Furniture</strong>
                    <p/>
                    <p/>
                    My desk of choice is the Ikea Idäsen. It's a sturdy and spacious desk that can accommodate all my
                    gadgets without feeling cluttered. I also love the electric height adjustment feature, which
                    allows me to stand up and work when I need to.
                    For my chair, I went with the Ikea Styrspel. It's comfortable, ergonomic, and affordable.
                    I spend long hours sitting on this chair, and I like the adjustable armrests and lumbar support it provides.
                    <p/>
                    <p/>
                    <strong>Screens</strong>
                    <p/>
                    <p/>
                    I'm currently on a two monitors setup: the Dell P2417H, which has rotation support, allowing it to
                    become my vertical monitor is great for when I'm working on code, rendering mobile device screens,
                    or reading long articles. And the SAMSUNG S60UA monitor, which is great for multitasking.
                    <p/>
                    <p/>
                    <strong>Gadgets</strong>
                    <p/>
                    <p/>
                    The Logitech mouse is a game changer. If you're using one without configurable buttons,
                    or that speed-adaptive scroll wheel which auto-shifts from click-to-click to hyper fast scroll when clicked.
                    you're missing out!
                    <p/>
                    Also, a keyboard wrist rest is a must. I've got that wooden one from a local shop back in Brazil, and I love it.
                    <p/>
                    <p/>
                    <p/>
                    <p/>
                  </p>
                </div>

              </PostFullContent>
            </article>
          </div>
        </main>
        <Footer/>
      </Wrapper>
    </IndexLayout>
  );
}

export default DeskSetup;
